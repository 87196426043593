<template>
	<Header :casinoName="reporterState?.casinoName" :isMobile="isMobile" />
	<div id="nav-container" :class="isMobile ? 'isMobile' : ''">
		<button id="navbar-button" v-if="isMobile" @click="toggleNavBar()" class="nav-button-container btn">
			<div></div>
			<div></div>
			<div></div>
		</button>
		<MainNavbar :class="navBarClass" :systemSettings="systemSettings" :reporterState="reporterState" :isMobile="isMobile" />
	</div>
	<Login :reporterState="reporterState" :casinoList="casinoList" :isMobile="isMobile" />
	<router-view
		id="router-view"
		:key="$route.fullPath"
		:reporterState="reporterState"
		:casinoList="casinoList"
		:isMobile="isMobile"
		:serverVersion="serverVersion"
		:casinoListForReports="casinoListForReports"
		:inGameCurrencyTool="inGameCurrencyTool"
		:systemCurrencyTool="systemCurrencyTool"
	/>
	<Footer :serverVersion="serverVersion" :isMobile="isMobile" />
	<component v-bind:is="currentAppModal" :reporterState="reporterState" />
</template>

<script>
// @ is an alias to /src
import store from "@/store/index";
import Login from "@/components/Login.vue";
import sharedScripts from "@/dependencies/sharedScripts";
import MainNavbar from "@/components/MainNavbar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CurrencyTool from "@/dependencies/currencyTool";

export default {
	name: "App",
	components: {
		Login,
		MainNavbar,
		Header,
		Footer,
	},
	data() {
		return {
			windowWidth: window.innerWidth,
			isMobile: this.windowWidth < 768,
			status: Object.assign({}, this.globalStatus),
			serverVersion: {},
			messageResetTimer: null,
			reporterState: this.session.get("reportApp") || {},
			currentAppModal: null,
			casinoList: [],
			mainNavbarOpen: false,
			navBarClass: "",
			casinoListForReports: [],
			inGameCurrencyTool: null,
			systemCurrencyTool: null,
			systemSettings: Object,
		};
	},
	watch: {
		windowWidth() {
			this.onResize();
		},
	},
	mounted() {
		let thisInstance = this;
		this.$nextTick(() => {
			window.addEventListener("resize", thisInstance.onResize);
			thisInstance.onResize();
		});
		window.onpopstate = (e) => {
			// Force browser back button to home
			// Web history state has problems when we deploy in a subfolder
			// This is to fix the back button causing empty page to be displayed
			thisInstance.$router.push("/");
		};
	},
	async created() {
		await this.checkServerVersion();
		await this.getCasinos();
		this.getSystemSettings();

		if (this.reporterState.userId) this.listCasinosForThisReporter();

		let thisInstance = this;
		this.eventBus.on("toggleNavBar", (forceClose = false) => {
			if (forceClose) {
				thisInstance.mainNavbarOpen = false;
				this.navBarClass = "";
			} else {
				thisInstance.toggleNavBar();
			}
		});
		this.eventBus.on("updateReporterState", (payload) => {
			thisInstance.updateReporterState(payload);
		});
		this.eventBus.on("refreshClientTimeout", () => {
			let newState = thisInstance.reporterState;
			newState.loggedOnTimeCode = new Date().getTime();
			thisInstance.updateReporterState(newState);
		});
		this.eventBus.on("updateCasinoList", () => {
			thisInstance.updateThisCasino();
		});
		this.eventBus.on("closeAppModal", () => {
			thisInstance.currentAppModal = null;
		});
		this.eventBus.on("openAppModal", (payload) => {
			thisInstance.currentAppModal = payload;
		});
		this.eventBus.on("updateErrorList", (payload) => {
			let newErrorList = JSON.parse(sessionStorage.getItem("errorList")) || store.state.errorList;
			if (newErrorList.length > 0 && newErrorList.length > 99) newErrorList.pop();
			newErrorList.push(payload);
			store.dispatch("setErrorList", newErrorList);
			sessionStorage.setItem("errorList", JSON.stringify(newErrorList));
		});
		this.eventBus.on("resetCurrencyToolConstructor", (currencyInfo) => {
			// This tool passes currencyInfo to the constructor for
			// in-game currency.
			this.inGameCurrencyTool = new CurrencyTool(currencyInfo, this.localeString);
			// This tool passes systemSettings.cashOutCurrency to the constructor for
			// real-world currency formatting info.
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
		});
	},
	methods: {
		async getSystemSettings() {
			let requestUrl = new URL("/api/v1/system/settings", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.systemSettings = dataJson;
				this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			} catch (e) {
				console.error(e);
				console.error("Server not responding to System Settings request");
			}
		},
		listCasinosForThisReporter() {
			let permissionsList = this.reporterState?.userPermissions?.sitePermissions;
			let list = [];
			for (const key in permissionsList) {
				if (permissionsList[key].includes("Reporter")) {
					let casino = this.casinoList.filter((casino) => casino.id == key)[0];
					list.push(casino);
				}
			}
			list = list.sort((a, b) => a.name.localeCompare(b.name));
			this.casinoListForReports = this.reporterState.isSysadmin ? this.casinoList : list;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			this.isMobile = this.windowWidth < 768 || false;
			this.mainNavbarOpen = false;
			this.navBarClass = "";
		},
		toggleNavBar() {
			this.mainNavbarOpen = this.mainNavbarOpen ? false : true;
			this.navBarClass = this.mainNavbarOpen ? "open" : "";
		},
		updateReporterState(changedState) {
			let mergedReporterState = {};
			if (Object.keys(changedState).length !== 0) {
				mergedReporterState = {
					...this.reporterState,
					...changedState,
				};
			}
			this.reporterState = mergedReporterState;
			this.reporterState.loggedOnTimeCode = new Date().getTime();
			this.listCasinosForThisReporter();
			this.inGameCurrencyTool = new CurrencyTool(this.reporterState.currencyInfo, this.localeString);
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			this.session.save("reportApp", mergedReporterState);
			store.dispatch("setReporterState", mergedReporterState);
		},
		async checkServerVersion() {
			let requestUrl = new URL("/api/v1/version", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.serverVersion = dataJson;
			} catch (e) {
				console.error(e);
				console.error("Server not responding to version request");
			}
		},
		async getCasinos() {
			try {
				// Get Casino List
				let response = await sharedScripts.getCasinoList(this);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				this.casinoList = await response.sort((a, b) => a.name.localeCompare(b.name));
				store.dispatch("setCasinoList", this.casinoList || []);
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
			}
		},
		async updateThisCasino() {
			try {
				let response = await sharedScripts.getCasinoList(this, this.reporterState.casinoId);
				let newCasino = await response;

				let mergedCasinoList = [];

				this.casinoList.forEach((casino) => {
					mergedCasinoList.push(casino.id === newCasino.id ? newCasino : casino);
				});

				this.casinoList = mergedCasinoList;
				store.dispatch("setCasinoList", mergedCasinoList || []);
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
			}
		},
	},
};
</script>

<style>
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden auto;
	color: #fff;
	background-color: #434250;
}

.gradient-background {
	background: linear-gradient(341deg, #f80626, #22df66, #6801d6, #49b7a9);
	background-size: 240% 240%;
	animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1,
h2,
h3,
.loading-message {
	text-align: center;
	display: block;
}

h1 {
	font-size: 1.5em;
}

.loading-message {
	margin: 15px;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

#nav-container {
	position: relative;
}

#nav-container.isMobile {
	z-index: 5;
}

.nav-button-container {
	position: fixed !important;
	top: 15px;
	left: 15px !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	padding: 0;
	margin: 0;
	z-index: 1;
	border-radius: 100% !important;
	overflow: hidden;
}

.nav-button-container div {
	margin: 3px;
	align-self: center;
	width: 30px;
	height: 4px;
	background-color: #fff;
	border-radius: 5px;
}

#router-view {
	position: relative;
	width: 100vw;
	margin: auto;
	padding: 15px 0 0;
	overflow: hidden auto;
	height: calc(100vh - 160px);
}

#app {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	user-select: none;
	cursor: pointer;
}

legend {
	margin: auto;
	padding: 0 15px;
	font-size: 1.75em;
	text-align: center;
}

input,
label,
select,
button {
	margin: 5px 10px;
}

input,
select,
textarea,
input:focus,
select:focus,
textarea:focus {
	font-size: 16px;
}

table {
	border-collapse: collapse;
}

button {
	width: auto;
}

input {
	color: #000;
	font-weight: 700;
}

th,
.header-row {
	font-weight: 700;
	text-transform: uppercase;
}

.sticky {
	position: sticky;
	top: -1px;
	text-align: center;
	z-index: 1;
}

#paging {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;
	justify-content: center;
	margin: auto;
	user-select: none;
}

.loading-icon {
	display: block;
	content: "";
	position: relative;
	top: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 1px solid;
	border-radius: 100%;
	border-color: red white blue black;
	animation: loader 0.5s linear infinite;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.outline-white {
	text-shadow: -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 1px 0px #fff, 1px 1px 0px #fff;
}

.outline-black {
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

.btn {
	position: relative;
	padding: 5px 10px;
	right: 15px;
	left: 0;
	background-color: #5b88c0;
	color: #fff;
	border-radius: 7px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%);
	border: none;
	transition: box-shadow 0.2 ease-in-out, transform 0.2s ease-in-out;
	cursor: pointer;
}

.btn:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}

.reg-au {
	color: #0000bf;
}

.promo-au {
	color: #812f00;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
	transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
	transform: translateY(100vh);
}

.slidedown-enter-active,
.slidedown-leave-active {
	transition: height 0.3s ease;
}

.slidedown-enter-from,
.slidedown-leave-to {
	height: 0;
}

.operator-id,
.cashier-id {
	text-align: left;
}

.operator-id span,
.cashier-id span {
	padding: 5px;
	font-weight: bold;
	border-radius: 100%;
	filter: contrast(1.5);
}

hr.spin-hr {
	background-color: #000;
	margin: 4em 30px;
}

hr.heading-divider {
	margin-top: 150px;
}

code {
	padding: 0.1em 0.2em;
	margin: 0;
	font-size: 0.8em !important;
	background-color: rgb(133 133 133 / 40%);
	border-radius: 6px;
	font-size: 1em;
	font-family: "Courier New", monospace;
	overflow-wrap: anywhere;
	white-space: pre-wrap;
}

.psuedo-anchor {
	color: rgb(0, 0, 238);
	cursor: pointer;
	text-decoration: underline;
}

.psuedo-anchor:visited {
	color: rgb(85, 26, 139);
}

.psuedo-anchor:active {
	color: rgb(255, 0, 0);
}

.psuedo-anchor:focus-visible {
	outline-color: rgb(16, 16, 16);
	outline-offset: 1px;
	outline-width: 1px;
}

.bold-red {
	color: white;
	background-color: red;
	font-weight: bold;
}

.bold-blue {
	color: white;
	background-color: #4971a4;
	font-weight: bold;
}

.bold-light-yellow {
	color: #000;
	background-color: #ffffbd;
	font-weight: bold;
}

@media (min-width: 768px) {
	h1 {
		font-size: 2em;
	}

	#router-view {
		width: 85%;
		margin-left: 15%;
	}

	.operator-id,
	.cashier-id {
		text-align: center;
	}
}
</style>
